<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<mat-progress-bar [class.visible]="(value$ | async) > 0 && (value$ | async) !== 100"
                  [value]="value$ | async"
                  class="progress-bar"
                  mode="determinate"></mat-progress-bar>
