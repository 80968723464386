/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {DateTime} from 'luxon';

export interface Notification {
  id: string;
  icon: string;
  label: string;
  colorClass: string;
  datetime: DateTime;
  read?: boolean;
}
