/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  // @ts-ignore
  valueChangesSubject = new BehaviorSubject<string>(undefined);
  valueChanges$ = this.valueChangesSubject.asObservable();

  submitSubject = new Subject<string>();
  submit$ = this.submitSubject.asObservable();

  isOpenSubject = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject.asObservable();

  constructor() {
  }
}
