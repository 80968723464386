/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService) {
  }

  public canActivate(): boolean {
    const token = this.authenticationService.getToken();
    if (token) {
      return true;
    }
    this.authenticationService.logOut();
    return false;
  }
}
