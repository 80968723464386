/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {ShareBottomSheetComponent} from './share-bottom-sheet.component';

@NgModule({
  declarations: [ShareBottomSheetComponent],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    MatListModule,
    RouterModule,
    MatIconModule,
  ],
  exports: [
    MatBottomSheetModule
  ]
})
export class ShareBottomSheetModule {
}
