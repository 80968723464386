/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError, timeout} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import Notiflix, {INotifyOptions} from "notiflix";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private token: AuthenticationService,
              private translate: TranslateService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.token.logOut();
        Notiflix.Notify.init({position: "right-bottom",});
        Notiflix.Notify.failure(this.translate.instant('message.error.auth.unauthorized'));
        // eslint-disable-next-line no-restricted-globals
        // location.reload(true);
      }

      if (err.status === 403) {
        this.router.navigateByUrl("/");
        Notiflix.Notify.init({position: "right-bottom",});
        Notiflix.Notify.failure(this.translate.instant('message.error.auth.forbidden'));
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }))
  }
}
