/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {DateTime} from 'luxon';

export function createDateArray(length: number) {
  const dates: number[] = [];
  for (let i = 0; i < length; i++) {
    dates.push(+DateTime.local().minus({day: i}).toJSDate());
  }
  return dates.reverse();
}

