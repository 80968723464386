/*
 * Copyright (C) 2023 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Injectable, OnInit} from '@angular/core';
import {WebSocketService} from "../../config/web-socket.service";
import {AuthenticationService} from "@pitagon/ngx-pids/security";
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";
import {ALERT_CHAT_TOPIC} from "./const-web-socket";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatSocketService implements OnInit{
  topicChat = ALERT_CHAT_TOPIC;
  currentUser: any;
  public list$ = new BehaviorSubject(null);
  listChatMessage: any = [];
  stompClient;

  public resourceId: any;

  constructor(private webSocketService : WebSocketService,
              private tokenService: AuthenticationService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getAccountInfo();
    this.connect();
  }

  // on error, schedule a reconnection attempt
  private connect(): void {
    console.log("receive" + this.resourceId);
    const stompClient = this.webSocketService.connectServer();
    const _this = this;
    stompClient.connect({userId : this.currentUser.id}, function(_frame) {
      console.log("Connected socket server with user-id: "+ this.currentUser.id);
      stompClient.subscribe(this.topicChat + "/" + this.resourceId, function(sdkEvent) {
        _this.onMessageReceived(sdkEvent);
      });
    }, this.errorCallBack);
  }

  errorCallBack(error) {
    console.log('errorCallBack -> ' + error);
    setTimeout(() => {
      this.connect();
    }, 5000);
  }
  onMessageReceived(message) {
    console.log('Message Recieved from Server :: ' + message);
    const obj = JSON.parse(message.body);
    console.log(obj);
    this.toastr.success(obj.content, 'Thông báo mới!',{enableHtml: true});
  }
}
