/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'ngx-pids-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarSearchComponent implements OnInit {

  // @ts-ignore
  isOpen: boolean;

  // @ts-ignore
  @ViewChild('input', {read: ElementRef, static: true}) input: ElementRef;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  open() {
    this.isOpen = true;
    this.cd.markForCheck();

    setTimeout(() => {
      // @ts-ignore
      this.input.nativeElement.focus();
    }, 100);
  }

  close() {
    this.isOpen = false;
    this.cd.markForCheck();
  }
}
