/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, Input} from '@angular/core';
import {ConfigService} from '@pitagon/ngx-pids/config';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'ngx-pids-secondary-toolbar',
  template: `
    <div class="secondary-toolbar-placeholder">&nbsp;</div>

    <div [ngClass]="{ 'fixed': fixed$ | async, 'w-full': !(fixed$ | async) }"
         class="secondary-toolbar shadow-b py-1 z-40 border-t flex">
      <div class="px-gutter flex items-center flex-auto" [class.container]="isVerticalLayout$ | async">
        <h1 *ngIf="current"
            class="subheading-2 font-medium m-0 ltr:pr-3 rtl:pl-3 ltr:border-r rtl:border-l ltr:mr-3 rtl:ml-3 flex-none">{{ current }}</h1>

        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./secondary-toolbar.component.scss']
})
export class SecondaryToolbarComponent {

  // @ts-ignore
  @Input() current: string;
  // @ts-ignore
  @Input() crumbs: string[];

  fixed$ = this.configService.config$.pipe(
    // @ts-ignore
    map(config => config.toolbar.fixed)
  );

  isVerticalLayout$: Observable<boolean> = this.configService.select(config => config.layout).pipe(
    map(layout => layout === 'vertical')
  );

  constructor(private readonly configService: ConfigService) {
  }
}
