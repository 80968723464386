/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {mergeDeep} from "@pitagon/ngx-pids/utils";
import ApexCharts from 'apexcharts';
import {asapScheduler} from 'rxjs';

export interface ApexOptions {
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  colors?: any[];
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  grid?: ApexGrid;
  labels?: string[] | number[];
  legend?: ApexLegend;
  markers?: ApexMarkers;
  noData?: ApexNoData;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  states?: ApexStates;
  stroke?: ApexStroke;
  subtitle?: ApexTitleSubtitle;
  theme?: ApexTheme;
  title?: ApexTitleSubtitle;
  tooltip?: ApexTooltip;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
}

export const defaultChartOptions = (options: Partial<ApexOptions> = {}): ApexOptions => mergeDeep({
  grid: {
    show: false,
    padding: {
      left: 0,
      right: 0
    }
  },
  chart: {
    parentHeightOffset: 0,
    type: 'area',
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true
    }
  },
  labels: [],
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2.5
  },
  fill: {
    gradient: {}
  },
  xaxis: {
    type: 'numeric',
    labels: {
      show: false,
      style: {
        cssClass: 'text-secondary fill-current caption font-medium',
        fontFamily: 'inherit'
      }
    },
    axisBorder: {
      show: true,
      color: '#EEEEEE'
    },
    axisTicks: {
      show: false
    },
    floating: false,
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
      style: {
        cssClass: 'text-secondary fill-current caption font-medium',
        fontFamily: 'inherit'
      }
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
  },
  legend: {
    position: 'top',
    fontFamily: 'inherit',
    horizontalAlign: 'left',
    offsetX: -18,
    itemMargin: {
      horizontal: 0,
      vertical: 12
    },
    markers: {
      radius: 4,
      width: 12,
      height: 12
    },
    labels: {
      colors: ['var(--text-secondary)']
    }
  },
  tooltip: {
    x: {show: false}
  },
}, options);

@Component({
  selector: 'ngx-pids-chart',
  template: `
    <div #chart></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit, OnChanges {

  // @ts-ignore
  @Input() options: ApexOptions;
  // @ts-ignore
  @Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  @Input() autoUpdateSeries = true;
  // @ts-ignore
  public chart: ApexCharts;
  // @ts-ignore
  @ViewChild('chart', {static: true}) private chartElement: ElementRef;

  constructor(private cd: ChangeDetectorRef,
              private ngZone: NgZone) {
  }


  ngOnInit() {
    asapScheduler.schedule(() => {
      this._createElement();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    asapScheduler.schedule(() => {
      if (this.autoUpdateSeries && Object.keys(changes).filter(c => c !== 'series').length === 0) {
        // @ts-ignore
        this.chart.updateSeries(this.series, true);
        return;
      }

      this._createElement();
    });
  }

  public render(): Promise<void> {
    // @ts-ignore
    return this.chart.render();
  }

  private _createElement() {
    if (this.series) {
      // @ts-ignore
      this.options.series = this.series;
    }

    if (this.chart) {
      this.chart.destroy();
    }

    this.ngZone.runOutsideAngular(() => {
      this.chart = new ApexCharts(
        // @ts-ignore
        this.chartElement.nativeElement,
        this.options
      );

      this.render();
    });
  }
}
