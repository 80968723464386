/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {I18nSwitcherModule, MegaMenuModule, NavigationItemModule} from '@pitagon/ngx-pids/components';
import {NavigationModule} from '../navigation/navigation.module';
import {ToolbarNotificationsModule} from './toolbar-notifications/toolbar-notifications.module';
import {ToolbarSearchModule} from './toolbar-search/toolbar-search.module';
import {ToolbarUserModule} from './toolbar-user/toolbar-user.module';
import {ToolbarComponent} from './toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    ToolbarNotificationsModule,
    ToolbarUserModule,
    ToolbarSearchModule,
    NavigationModule,
    RouterModule,
    NavigationItemModule,
    MegaMenuModule,
    I18nSwitcherModule
  ],
  exports: [ToolbarComponent, ToolbarNotificationsModule, ToolbarSearchModule, ToolbarUserModule]
})
export class ToolbarModule {
}
