/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component} from "@angular/core";
import {Subscription} from "rxjs";

@Component({template: '',})
export class GeneralComponent {

  protected subscriptions: Subscription = new Subscription();

  constructor() {
  }

  protected subscribe(subscription) {
    this.subscriptions.add(subscription);
  }

  protected unsubscribeAllSubscriptions() {
    this.subscriptions.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }

}
