/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Directive} from '@angular/core';

@Directive({
  selector: '[ngxPiDsPageLayoutContent],ngx-pids-page-layout-content',
  host: {
    class: 'ngx-pids-page-layout-content'
  }
})
export class PageLayoutContentDirective {

  constructor() {
  }

}
