/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from "@pitagon/ngx-pids/security";
import {QuicklinkModule, QuicklinkStrategy} from "ngx-quicklink";
import {AppLayoutComponent} from "./app-layout";

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('src/@pitagon/ngx-pids/pages/src/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('src/app/pages/apps/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('src/app/pages/apps/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('src/demo/pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        // redirectTo: '/apps/monitoring',
        // pathMatch: 'full'
        loadChildren: () => import('./pages/apps/statistic/statistic.module').then(m => m.StatisticsComponentModule),
      },
      {
        path: 'chat-demo',
        loadChildren: () => import('src/demo/pages/apps/chat/chat.module').then(m => m.ChatModule),
      },
      {
        path: 'apps',
        children: [
          {
            path: 'statistic',
            loadChildren: () => import('./pages/apps/statistic/statistic.module').then(m => m.StatisticsComponentModule),
          },
          {
            path: 'settings',
            loadChildren: () => import('src/app/pages/apps/settings/settings.module').then(m => m.SettingsModule),
          },{
            path: 'menus',
            loadChildren: () => import('src/app/pages/apps/menu/menu.module').then(m => m.MenuModule)
          },
          {
            path: 'roles',
            loadChildren: () => import('src/app/pages/apps/role/role.module').then(m => m.RoleModule)
          },
          {
            path: 'com-cats',
            loadChildren: () => import('src/app/pages/apps/com-cat/com-cat.module').then(m => m.ComCatModule)
          },
          {
            path: 'monitoring',
            loadChildren: () => import('src/app/pages/apps/monitoring/monitoring.module').then(m => m.MonitoringModule)
          },
          {
            path: 'device-monitoring',
            loadChildren: () => import('src/app/pages/apps/device-monitoring/device-monitoring.module').then(m => m.DeviceMonitoringModule)
          },
          {
            path: 'human-resource',
            loadChildren: () => import('src/app/pages/apps/human-resource/human-resource.module').then(m => m.HumanResourceModule)
          },
          {
            path: 'tenant-management',
            loadChildren: () => import('src/app/pages/apps/tenant-management/tenant-management.module').then(m => m.TenantManagementModule)
          },
          {
            path: 'vehicle',
            loadChildren: () => import('src/app/pages/apps/vehicle/vehicle.module').then(m => m.VehicleModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'water-resource',
            loadChildren: () => import('src/app/pages/apps/water-resource/water-resource.module').then(m => m.WaterResourceModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'incident-management',
            loadChildren: () => import('src/app/pages/apps/incident-management/incident-management.module').then(m => m.IncidentManagementModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'facility-management',
            loadChildren: () => import('src/app/pages/apps/facility-management/facility-management.module').then(m => m.FacilityManagementModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'coordination-units-management',
            loadChildren: () => import('src/app/pages/apps/coordination-units/coordination-units.module').then(m => m.CoordinationUnitsModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'activity-log',
            loadChildren: () => import('src/app/pages/apps/activity-log/activity-log.module').then(m => m.ActivityLogModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'statistics',
            loadChildren: () => import('./pages/apps/statistic/statistic.module').then(m => m.StatisticsComponentModule)
          }
        ]
      },
      {
        path: '**',
        loadChildren: () => import('src/demo/pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
