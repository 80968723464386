/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, OnInit} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {delayWhen, interval, Observable, of} from 'rxjs';

@Component({
  selector: 'ngx-pids-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  value$: Observable<number> = this.loader.useRef('router').value$.pipe(
    delayWhen(value => value === 0 ? interval(200) : of(undefined))
  );

  constructor(public loader: LoadingBarService) {
  }

  ngOnInit() {
  }

}
