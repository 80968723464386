/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {ActivatedRouteSnapshot} from '@angular/router';
import {NgxPiDsRouteData} from '@pitagon/ngx-pids/interfaces';

// @ts-ignore
export function checkRouterChildrenData(route: ActivatedRouteSnapshot & { data?: NgxPiDsRouteData }, compareWith: (data: NgxPiDsRouteData) => boolean) {
  if (compareWith(route.data)) {
    return true;
  }
  if (!route.firstChild) {
    return false;
  }
  return checkRouterChildrenData(route.firstChild, compareWith);
}

/**
 * Used to get params from children in their parent
 */
export function getAllParams(route: ActivatedRouteSnapshot & { data?: NgxPiDsRouteData }, result = new Map<string, string>()): Map<string, string> {
  if (route.params) {
    for (const key of Object.keys(route.params)) {
      result.set(key, route.params[key]);
    }
  }
  if (!route.firstChild) {
    return result;
  }
  return getAllParams(route.firstChild, result);
}
