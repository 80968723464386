<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="/assets/images/demo/logo.svg"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">PiDS</h1>
  </a>

<!--  <button *ngIf="isHorizontalLayout$ | async"-->
<!--          [class.hidden]="mobileQuery"-->
<!--          [matMenuTriggerFor]="addNewMenu"-->
<!--          color="primary"-->
<!--          mat-flat-button-->
<!--          type="button">Add New-->
<!--  </button>-->
  <img src="assets/images/icons/logos/PCCC_CNCH.jpg" style="width: 70px; margin-right: 5px">
  <h3>CỤC CẢNH SÁT PCCC VÀ CNCH</h3>

<!--  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">-->
<!--    <button mat-menu-item>-->
<!--      <mat-icon svgIcon="mat:person_add"></mat-icon>-->
<!--      <span>Add Contact</span>-->
<!--    </button>-->

<!--    <button mat-menu-item>-->
<!--      <mat-icon svgIcon="mat:done_all"></mat-icon>-->
<!--      <span>Add Task</span>-->
<!--    </button>-->

<!--    <button mat-menu-item>-->
<!--      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>-->
<!--      <span>Add Project</span>-->
<!--    </button>-->

<!--    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>-->
<!--      <mat-icon svgIcon="mat:ballot"></mat-icon>-->
<!--      <span>Add Document</span>-->
<!--    </button>-->
<!--  </mat-menu>-->

<!--  <mat-menu #documentMenu="matMenu">-->
<!--    <button mat-menu-item>-->
<!--      <mat-icon svgIcon="mat:description"></mat-icon>-->
<!--      <span>Add Quote</span>-->
<!--    </button>-->

<!--    <button mat-menu-item>-->
<!--      <mat-icon svgIcon="mat:assignment"></mat-icon>-->
<!--      <span>Add Invoice</span>-->
<!--    </button>-->

<!--    <button mat-menu-item>-->
<!--      <mat-icon svgIcon="mat:receipt"></mat-icon>-->
<!--      <span>Add Receipt</span>-->
<!--    </button>-->
<!--  </mat-menu>-->

<!--  <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">-->
<!--    <button *ngIf="isHorizontalLayout$ | async"-->
<!--            [class.hidden]="mobileQuery"-->
<!--            (click)="openMegaMenu(megaMenuOriginRef)"-->
<!--            [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }"-->
<!--            color="primary"-->
<!--            mat-button-->
<!--            type="button">-->
<!--      <span>Mega Menu</span>-->
<!--      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>-->
<!--    </button>-->
<!--  </div>-->


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <ngx-pids-navigation-item *ngFor="let item of navigationItems" [item]="item"></ngx-pids-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <ngx-pids-toolbar-notifications></ngx-pids-toolbar-notifications>
    </div>

    <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <ngx-pids-i18n-switcher display="icon" xPosition="before" yPosition="above"></ngx-pids-i18n-switcher>
    </div>

    <div *ngIf="userVisible$ | async" class="px-1">
      <ngx-pids-toolbar-user></ngx-pids-toolbar-user>
    </div>
  </div>
</div>

<ngx-pids-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                     [class.hidden]="mobileQuery"></ngx-pids-navigation>
