/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export interface MenuItem {
  id: string;
  icon: string;
  label: string;
  description: string;
  colorClass: string;
  route: string;
}
