/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Route} from '@angular/router';

export interface NgxPiDsRouteData {
  scrollDisabled?: boolean;
  toolbarShadowEnabled?: boolean;
  containerEnabled?: boolean;

  [key: string]: any;
}

export interface NgxPiDsRoute extends Route {
  data?: NgxPiDsRouteData;
  children?: NgxPiDsRoute[];
}

export type NgxPiDsRoutes = NgxPiDsRoute[];
