import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogConfirmComponent} from "./dialog-confirm.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";


@NgModule({
  declarations: [DialogConfirmComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
  ]
})
export class DialogConfirmModule {
}
