/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ScrollbarModule, SearchModalComponent, UserMenuModule} from '@pitagon/ngx-pids/components';
import {SidenavItemModule} from './sidenav-item/sidenav-item.module';
import {SidenavComponent} from './sidenav.component';

@NgModule({
  declarations: [SidenavComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    SidenavItemModule,
    ScrollbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatRippleModule,
    UserMenuModule,
    SearchModalComponent
  ],
  exports: [SidenavComponent, SidenavItemModule]
})
export class SidenavModule {
}
