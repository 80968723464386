<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<button mat-icon-button type="button" onfocus="this.blur()" (click)="openLanguageMenu()" class="i18n-switcher-btn"
        [matMenuTriggerFor]="languageMenu" #clickLanguageMenuTrigger="matMenuTrigger">
  <mat-icon *ngIf="display === 'icon' || display === 'icon-text'" svgIcon="flag:{{currentLanguage.flag}}"
            class="i18n-switcher-btn-icon_{{display}}"></mat-icon>
  <span
    *ngIf="display === 'text' || display === 'icon-text'"
    class="i18n-switcher-btn-text_{{display}}">{{'lang.' + currentLanguage.languageCode | translate}}</span>
</button>
<mat-menu #languageMenu="matMenu">
  <button mat-menu-item *ngFor="let language of languageItems$ | async; index as idx;"
          (click)="switchLang(language.languageCode, idx)">
    <mat-icon svgIcon="flag:{{language.flag}}"></mat-icon>
    <span>{{'lang.' + language.languageCode | translate}}</span>
    <span *ngIf="currentLanguage.languageCode !== language.languageCode">
          &nbsp;({{language.languageNativeName}})
        </span>
  </button>
</mat-menu>
