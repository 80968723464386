<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<ng-template #sidenavRef>
  <ngx-pids-sidenav [collapsed]="sidenavCollapsed$ | async"></ngx-pids-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <ngx-pids-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
                    [mobileQuery]="!(isDesktop$ | async)"
                    class="ngx-pids-toolbar"></ngx-pids-toolbar>
</ng-template>

<ng-template #footerRef>
  <ngx-pids-footer *ngIf="isFooterVisible$ | async" class="ngx-pids-footer"></ngx-pids-footer>
</ng-template>

<ng-template #quickpanelRef>
  <ngx-pids-quickpanel></ngx-pids-quickpanel>
</ng-template>

<ngx-pids-layout [footerRef]="footerRef"
                 [quickpanelRef]="quickpanelRef"
                 [sidenavRef]="sidenavRef"
                 [toolbarRef]="toolbarRef"></ngx-pids-layout>

<!--<ngx-pids-config-panel-toggle (openConfig)="configpanel.open()"></ngx-pids-config-panel-toggle>-->

<!-- CONFIGPANEL -->
<!--<ngx-pids-sidebar #configpanel [invisibleBackdrop]="true" position="right">-->
<!--  <ngx-pids-config-panel></ngx-pids-config-panel>-->
<!--</ngx-pids-sidebar>-->
<!-- END CONFIGPANEL -->
