/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {DEFAULT_ENVIRONMENT} from "./environment.default";

export const environment = {
  ...DEFAULT_ENVIRONMENT,
  production: true,
  appUrl: 'https://demo.pisafe.vn',
  apiUrl: 'https://api-authority.dev.pisafe.vn/api/authority',
  mediaStaticUrl: 'https://static.dev.pisafe.vn',
  webSocket : {
    endPoint: 'https://api.dev.pisafe.vn',
    topic: '/topic/alert'
  },

  host: 'https://api.dev.pisafe.vn',
  base_api: '/api/admin',
  port: 443,
  access_control_allow_origin: 'https://api.dev.pisafe.vn',
  host_static_img: 'https://static.dev.pisafe.vn'
};
