/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export interface CountryLanguage {
  countryCode: string, // Alpha-2
  languageCode: string, // Alpha-2
  languageEnglishName: string,
  languageNativeName: string,
  flag: string,
}
