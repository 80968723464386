<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<div class="navigation flex items-center container">
  <ngx-pids-navigation-item *ngFor="let item of items" [item]="item"></ngx-pids-navigation-item>
</div>

