/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export enum NgxPiDsConfigName {
  pids = 'ngx-pids-layout-pids',
  pitagon = 'ngx-pids-layout-pitagon',
  piweb = 'ngx-pids-layout-piweb',
  pisafe = 'ngx-pids-layout-pisafe',
  pioffice = 'ngx-pids-layout-pioffice',
  piads = 'ngx-pids-layout-piads',
  petapp = 'ngx-pids-layout-petapp',
}
