/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export * from './app-layout.component';
export * from './app-layout.module';

export * from './footer/footer.component';
export * from './footer/footer.module';
