/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-pids-breadcrumb',
  template: `
    <ng-content></ng-content>
  `,
  styles: [],
  host: {
    class: 'ngx-pids-breadcrumb body-2 text-hint leading-none hover:text-primary no-underline trans-ease-out ltr:mr-2 rtl:ml-2'
  }
})
export class BreadcrumbComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
