/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ConfigService} from '@pitagon/ngx-pids/config';
import {LayoutService} from '@pitagon/ngx-pids/services';
import {checkRouterChildrenData} from '@pitagon/ngx-pids/utils';
import {filter, map, startWith} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ngx-pids-custom-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildrenData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  // @ViewChild('configpanel', {static: true}) configpanel: SidebarComponent;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private breakpointObserver: BreakpointObserver,
              private router: Router) {
  }

  ngOnInit() {
    // this.layoutService.configpanelOpen$.pipe(
    //   untilDestroyed(this)
    // ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
  }
}
