/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const REMOTE_API = {
  resourceEndPoint: {
    actionEndPoint: '/resource-end-point/action-end-point',
  },
}
