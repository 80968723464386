/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {NavigationDropdown, NavigationItem, NavigationLink} from "@pitagon/ngx-pids/interfaces";
import {AccountDto, MenuDto, PermissionDto, RoleDto} from "@pitagon/ngx-pids/models";
import {ConfigService} from "@pitagon/ngx-pids/config";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationApi} from "@pitagon/ngx-pids/security";
import {Notification} from "../../../../../app/pages/common/Notification";

const TOKEN_KEY = "AUTH_TOKEN";
const USER_KEY = "AUTH_USER";
const AUTHORITIES_KEY = "AUTH_AUTHORITIES";
const USER_INFO_KEY = "AUTH_USER_INFO";
const ROLES_KEY = "AUTH_ROLES";
const MENUS_KEY = "AUTH_MENUS";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {

  menuItems: NavigationItem[] = [];
  private roles: Array<string> = [];
  route;
  errorMessage: string;

  constructor(private router: Router,
              private configService: ConfigService,
              private httpClient: HttpClient) {
    this.route = configService.currentConfig.route;
  }

  public logOut(): void {
    if (!this.getToken()) {
      this.clearLocalDataUser();
      this.showLogin();
    } else {
      let authenticationApi = new AuthenticationApi(this.httpClient, this.configService);
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.getToken()
        })
      };
      authenticationApi.logout(httpOptions).then(res => {
        if (res.data) {
          this.clearLocalDataUser();
          this.showLogin();
        }
      })
        .catch((error) => {
          this.clearLocalDataUser();
          this.showLogin();
        });
    }
  }

  public setAccountInfo(acc: AccountDto): void {
    localStorage.removeItem(USER_INFO_KEY);
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(acc));
  }

  public getAccountInfo(): AccountDto {
    return JSON.parse(localStorage.getItem(USER_INFO_KEY));
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUserName(userName: string): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, userName);
  }

  public getUserName(): string {
    return localStorage.getItem(USER_KEY);
  }

  public saveAuthorities(authorities: string[]): void {
    localStorage.removeItem(AUTHORITIES_KEY);
    localStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.roles = [];
    if (localStorage.getItem(TOKEN_KEY)) {
      JSON.parse(localStorage.getItem(AUTHORITIES_KEY)).forEach(
        (authority) => {
          this.roles.push(authority.authority);
        }
      );
    }
    return this.roles;
  }

  public saveRoles(per: RoleDto[]): void {
    localStorage.removeItem(ROLES_KEY);
    localStorage.setItem(ROLES_KEY, JSON.stringify(per));
  }

  public getPermissions(): PermissionDto[] {
    const roles = JSON.parse(localStorage.getItem(ROLES_KEY));
    let pers: PermissionDto[] = [];
    roles.forEach(role => {
      if (role.permissions) {
        role.permissions.forEach(permission => {
          pers.push(permission);
        });
      }
    });
    return pers;
  }

  public buildMenu(): void {
    const menus = this.getMenuByRole();
    if (!menus) {
      return null;
    }
    const parentMenus: MenuDto[] = menus.filter(s => s.parentId == null);
    const subMenus: MenuDto[] = [];
    menus.forEach((menu) => {
      if (menu.parentId != null) {
        subMenus.push(menu);
      }
    });
    if (parentMenus.length > 0) {
      parentMenus.forEach(parent => {
        parent.children = [];
        let menuChilds: NavigationLink[] = [];
        let menuLink: NavigationLink = {label: parent.name, icon: parent.icon, route: parent.url, type: 'link'};

        if (subMenus && subMenus.length > 0) {
          subMenus.forEach((sub) => {
            if (sub.parentId != null && sub.parentId == parent.id) {
              let menuChild: NavigationLink;
              parent.children.push(sub);
              menuChild = {label: sub.name, route: sub.url, icon: sub.icon, type: 'link'};
              menuChilds.push(menuChild);
            }
          });
        }
        if (parent.children && parent.children.length > 0) {
          let menuDropdown: NavigationDropdown;
          menuDropdown = {label: parent.name, icon: parent.icon, type: 'dropdown', children: menuChilds}
          this.menuItems.push(menuDropdown);
        } else {
          this.menuItems.push(menuLink);
        }
      })
    }
    localStorage.removeItem(MENUS_KEY);
    localStorage.setItem(MENUS_KEY, JSON.stringify(this.menuItems));
  }

  public getMenus(): NavigationItem[] {
    return JSON.parse(localStorage.getItem(MENUS_KEY));
  }

  public hasPermission(resourceCode: string, operationCode: string) {
    const data = this.getPermissions();
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item && item.resourceCode == resourceCode && item.operationCode == operationCode) {
          return true;
        }
      }
    }
    return false;
  }

  private getMenuByRole(): MenuDto[] {
    const roles = JSON.parse(localStorage.getItem(ROLES_KEY));
    if (!roles) {
      return null;
    }
    let menus: MenuDto[] = [];
    roles.forEach(role => {
      if (role.menu) {
        role.menu.forEach(m => {
          menus.push(m);
        });
      }
    });
    return menus;
  }

  private showLogin(): void {
    this.router.navigate(['/login']);
  }

  private clearLocalDataUser() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(USER_INFO_KEY);
    localStorage.removeItem(ROLES_KEY);
  }

  public setErrorMessage(err) {
    this.errorMessage = err;
  }
  public getErrorMessage(): string {
    return this.errorMessage;
  }

}
