<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<button (click)="openConfig.emit()" class="config-panel-toggle" color="primary" mat-fab type="button">
  <mat-icon svgIcon="mat:settings"></mat-icon>
</button>
