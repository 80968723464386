/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export * from './src/colorSchemeName';
export * from './src/config.service';
export * from './src/config-name.model';
export * from './src/configs';
export * from './src/constants';
export * from './src/default-country-language.datasource';
export * from './src/default-remote-api';
export * from './src/default-route-url';
export * from './src/ngx-pids-config.interface';
