/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenu, MatMenuTrigger, MenuPositionX, MenuPositionY} from "@angular/material/menu";
import {Observable} from "rxjs";
import {CountryLanguage} from "@pitagon/ngx-pids/interfaces";
import {map} from "rxjs/operators";
import {ConfigService} from "@pitagon/ngx-pids/config";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {CountryLanguageService} from "@pitagon/ngx-pids/services";
import {GeneralComponent} from "@pitagon/ngx-pids/utils";

@Component({
  selector: 'ngx-pids-i18n-switcher',
  templateUrl: './i18n-switcher.component.html',
  styleUrls: ['./i18n-switcher.component.scss']
})
export class I18nSwitcherComponent extends GeneralComponent implements OnInit, OnDestroy {

  @Input() display: 'icon' | 'text' | 'icon-text' = 'icon-text';
  @Input() overlapTrigger: boolean = false;
  @Input() xPosition: MenuPositionX = 'after';
  @Input() yPosition: MenuPositionY = 'below';

  languageItems$: Observable<CountryLanguage[]> = this.configService.config$.pipe(map(config => config.i18n?.languages));
  currentLanguage: CountryLanguage = this.countryLanguageService.currentCountryLanguage;
  selectedLanguageMenuItemIndex: number;
  @ViewChild('languageMenu')
  languageMenu: MatMenu;
  @ViewChild('clickLanguageMenuTrigger')
  clickLanguageMenuTrigger: MatMenuTrigger;

  constructor(private configService: ConfigService,
              private translate: TranslateService,
              private countryLanguageService: CountryLanguageService) {
    super();
  }

  ngOnInit(): void {
    this.selectedLanguageMenuItemIndex = this.configService.currentConfig?.i18n?.languages?.findIndex(item => {
      return item.languageCode === this.currentLanguage?.languageCode;
    });
    this.subscriptions.add(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      let _currentLang = this.countryLanguageService.getByLanguageCode(event.lang);
      this.currentLanguage = _currentLang;
      this.countryLanguageService.currentCountryLanguage = _currentLang;
    }));
    this._updatePosition();
  }

  private _updatePosition(): void {
    if (this.languageMenu) {
      this.languageMenu.overlapTrigger = this.overlapTrigger;
      this.languageMenu.xPosition = this.xPosition;
      this.languageMenu.yPosition = this.yPosition;
      this.clickLanguageMenuTrigger.updatePosition();
    }
  }

  openLanguageMenu(): void {
    this._updatePosition();
    this.clickLanguageMenuTrigger.openMenu();
    this.languageMenu._allItems.get(this.selectedLanguageMenuItemIndex)?.focus();
  }

  switchLang(lang: string, idx: number) {
    this.translate.use(lang);
    this.selectedLanguageMenuItemIndex = idx;
  }

}
