/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {CSSValue, DeepPartial} from '@pitagon/ngx-pids/interfaces';
import {LayoutService} from '@pitagon/ngx-pids/services';
import {mergeDeep} from '@pitagon/ngx-pids/utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ColorSchemeName} from './colorSchemeName';
import {NgxPiDsConfigName} from './config-name.model';
import {configs} from './configs';
import {NgxPiDsConfig} from './ngx-pids-config.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  defaultConfig: NgxPiDsConfigName = NgxPiDsConfigName.pitagon;
  configs: NgxPiDsConfig[] = configs;

  private _configSubject = new BehaviorSubject(this.configs.find(c => c.id === this.defaultConfig));
  config$ = this._configSubject.asObservable();
  private _currentConfig: NgxPiDsConfig;

  constructor(@Inject(DOCUMENT) private document: Document,
              private translateService: TranslateService,
              private layoutService: LayoutService) {
    // @ts-ignore
    this.config$.subscribe(config => this._updateConfig(config));
  }

  select<R>(selector: (config: NgxPiDsConfig) => R): Observable<R> {
    return this.config$.pipe(
      // @ts-ignore
      map(selector)
    );
  }

  setConfig(config: NgxPiDsConfigName) {
    const settings = this.configs.find(c => c.id === config);
    this._configSubject.next(settings);
  }

  get currentConfig(): NgxPiDsConfig {
    return this._currentConfig;
  }

  updateConfig(config: DeepPartial<NgxPiDsConfig>) {
    // @ts-ignore
    this._configSubject.next(mergeDeep({...this._configSubject.getValue()}, config));
  }

  private _updateConfig(config: NgxPiDsConfig): void {
    this._setI18n(config.i18n);
    this._setSecurity(config.security);
    this._setRemoteApi(config.remoteApi);
    this._setRoute(config.route);
    this._setLayoutClass(config.id);
    this._setStyle(config.style);
    this._setDirection(config.direction);
    this._setSidenavState(config.sidenav.state);
    this._emitResize();
    this._currentConfig = config;
  }

  private _setI18n(i18n: NgxPiDsConfig['i18n']): void {
    // Find only new language to add in TranslateService
    const currentConfigLangCodes = this._currentConfig?.i18n?.languages?.map(language => language.languageCode);
    const newConfigLangCodes = i18n.languages?.map(language => language.languageCode);
    const newLangCodes = newConfigLangCodes?.filter(langCode => !currentConfigLangCodes?.includes(langCode));
    if (newLangCodes?.length)
      this.translateService.addLangs(newLangCodes);
    // Set new default language
    if (this._currentConfig?.i18n?.defaultLanguage?.languageCode !== i18n.defaultLanguage?.languageCode)
      this.translateService.setDefaultLang(i18n.defaultLanguage?.languageCode);
  }

  private _setSecurity(security: NgxPiDsConfig['security']): void {
  }

  private _setRemoteApi(remoteApi: NgxPiDsConfig['remoteApi']): void {
  }

  private _setRoute(route: NgxPiDsConfig['route']): void {
  }

  private _setStyle(style: NgxPiDsConfig['style']): void {
    /**
     * Color Scheme
     */
    Object.values(ColorSchemeName).filter(s => s !== style.colorScheme).forEach(value => {
      if (this.document.body.classList.contains(value)) {
        this.document.body.classList.remove(value);
      }
    });

    this.document.body.classList.add(style.colorScheme);

    /**
     * Border Radius
     */
    this.document.body.style.setProperty('--border-radius', `${style.borderRadius.value}${style.borderRadius.unit}`);

    const buttonBorderRadius: CSSValue = style.button.borderRadius ?? style.borderRadius;
    this.document.body.style.setProperty('--button-border-radius', `${buttonBorderRadius.value}${buttonBorderRadius.unit}`);

    /**
     * Primary Color
     */
    this.document.body.style.setProperty('--color-primary', style.colors.primary.default.replace('rgb(', '').replace(')', ''));
    this.document.body.style.setProperty('--color-primary-contrast', style.colors.primary.contrast.replace('rgb(', '').replace(')', ''));
  }

  /**
   * Emit event so charts and other external libraries know they have to resize on layout switch
   * @private
   */
  private _emitResize(): void {
    if (window) {
      window.dispatchEvent(new Event('resize'));
      setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
    }
  }

  private _setDirection(direction: 'ltr' | 'rtl') {
    this.document.body.dir = direction;
  }

  private _setSidenavState(sidenavState: 'expanded' | 'collapsed'): void {
    sidenavState === 'expanded' ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  private _setLayoutClass(layout: NgxPiDsConfigName): void {
    this.configs.forEach(c => {
      if (this.document.body.classList.contains(c.id)) {
        this.document.body.classList.remove(c.id);
      }
    });

    this.document.body.classList.add(layout);
  }
}
