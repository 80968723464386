/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, Input, OnInit} from '@angular/core';
import {createDateArray} from '@pitagon/ngx-pids/utils';
import {ApexOptions, defaultChartOptions} from '../../chart/chart.component';

@Component({
  selector: 'ngx-pids-widget-large-goal-chart',
  templateUrl: './widget-large-goal-chart.component.html'
})
export class WidgetLargeGoalChartComponent implements OnInit {

  // @ts-ignore
  @Input() total: string;
  // @ts-ignore
  @Input() series: ApexNonAxisChartSeries | ApexAxisChartSeries;
  @Input() options: ApexOptions = defaultChartOptions({
    grid: {
      show: true,
      strokeDashArray: 3,
      padding: {
        left: 16
      }
    },
    chart: {
      type: 'line',
      height: 300,
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false
      }
    },
    stroke: {
      width: 4
    },
    labels: createDateArray(12),
    xaxis: {
      type: 'datetime',
      labels: {
        show: true
      }
    },
    yaxis: {
      labels: {
        show: true
      }
    }
  });

  constructor() {
  }

  ngOnInit() {
  }

}
