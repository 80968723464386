<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<div class="card bg-primary flex">
  <div class="p-4 ml-4 flex-none self-center hidden sm:block">
    <img src="/assets/images/illustrations/checklist.svg" style="height: 168px; width: 152px">
  </div>

  <div class="p-6 text-primary-contrast flex-auto">
    <h2 class="headline m-0">Good Job, David!</h2>
    <p class="caption m-0 opacity-50">You've finished all of your tasks for this week.</p>

    <div class="mt-4 flex items-center">
      <mat-icon class="mr-2" svgIcon="mat:check_circle"></mat-icon>
      <span class="body-2 cursor-pointer">Finish Dashboard Design</span>
    </div>

    <div class="mt-1 flex items-center">
      <mat-icon class="mr-2" svgIcon="mat:check_circle"></mat-icon>
      <span class="body-2 cursor-pointer">Fix Issue #74</span>
    </div>

    <div class="mt-1 flex items-center">
      <mat-icon class="mr-2" svgIcon="mat:check_circle"></mat-icon>
      <span class="body-2 cursor-pointer">Publish version 2.3.3</span>
    </div>
  </div>
</div>
