<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<div class="flex items-center">
  <button (click)="open()" mat-icon-button>
    <mat-icon svgIcon="mat:search"></mat-icon>
  </button>
  <mat-form-field [class.search-open]="isOpen" appearance="outline" class="search flex-auto">
    <mat-label>Search&hellip;</mat-label>
    <input #input (blur)="close()" matInput>
  </mat-form-field>
</div>
