/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CountryLanguage} from "@pitagon/ngx-pids/interfaces";

export const COUNTRY_LANGUAGE_DATASOURCE: CountryLanguage[] = [
  {
    countryCode: "JP",
    languageCode: "ja",
    languageEnglishName: "Japanese",
    languageNativeName: "日本語",
    flag: "japan",
  },
];
