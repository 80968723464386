/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TableColumn} from '@pitagon/ngx-pids/interfaces';

@Component({
  selector: 'ngx-pids-widget-table',
  templateUrl: './widget-table.component.html'
})
export class WidgetTableComponent<T> implements OnInit, OnChanges, AfterViewInit {

  // @ts-ignore
  @Input() data: T[];
  // @ts-ignore
  @Input() columns: TableColumn<T>[];
  @Input() pageSize = 6;

  // @ts-ignore
  visibleColumns: Array<keyof T | string>;
  dataSource = new MatTableDataSource<T>();

  // @ts-ignore
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      // @ts-ignore
      this.visibleColumns = this.columns.map(column => column.property);
    }

    if (changes.data) {
      // @ts-ignore
      this.dataSource.data = this.data;
    }
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.dataSource.sort = this.sort;
  }
}
