import {environment} from "../../environments/environment";
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {Injectable} from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  webSocketEndPoint = environment.webSocket.endPoint;
  stompClient: any;
  constructor() {
  }

  connectServer(){
    console.log('Initialize WebSocket Connection');
    const ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    this.stompClient.debug = true;
    return this.stompClient;
  }

  disconnectServer(): void {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
    console.log('WebSocket Disconnected');
  }
}
