/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'ngx-pids-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @Input() customTemplate: TemplateRef<any>;
  currentYear: number = new Date().getFullYear();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
