/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {MegaMenuComponent} from './mega-menu.component';

@NgModule({
  declarations: [MegaMenuComponent],
  imports: [
    CommonModule,
    MatButtonModule,

    RouterModule,
    MatIconModule
  ],
  exports: [MegaMenuComponent]
})
export class MegaMenuModule {
}
