/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import Notiflix from "notiflix";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private translateService: TranslateService) {
  }

  showWaiting(title?: string): void {
    if (title) {
      Notiflix.Loading.circle(title);
    } else {
      this.translateService.get('text.pleaseWait').subscribe(text => {
        Notiflix.Loading.circle(text);
      });
    }
  }

  showWaitingBlock(selectorOrHtmlElements?, title?: string): void {
    Notiflix.Block.init({
      backgroundColor: "#1a202e",
      messageColor: "#dcdcdc",
      svgColor: "#38c785",
    });
    if (title) {
      Notiflix.Block.circle(selectorOrHtmlElements, title);
    } else {
      this.translateService.get('text.pleaseWait').subscribe(text => {
        Notiflix.Block.circle(selectorOrHtmlElements, text);
      });
    }
  }

  showSuccess(title: string, message: string, buttonText: string, callbackOrOptions?): void {
    Notiflix.Loading.remove();
    if (buttonText) {
      Notiflix.Report.success(title, message, buttonText, callbackOrOptions);
    } else {
      this.translateService.get('label.ok').subscribe(text => {
        Notiflix.Report.success(title, message, text, callbackOrOptions);
      });
    }
  }

  showErrorStatus(title: string, message: string, callbackOrOptions?): void {
    Notiflix.Loading.remove();
    this.translateService.get('label.close').subscribe(text => {
      Notiflix.Report.failure(title, message, text, callbackOrOptions);
    });
  }

  showErrorMessage(err, message): void {
    Notiflix.Loading.remove();
    Notiflix.Report.failure(err, err, message);
  }

  clearWaitNoMessage(): void {
    Notiflix.Loading.remove();
  }

  clearWaitBlockNoMessage(selectorOrHtmlElements, delay?: number): void {
    Notiflix.Block.remove(selectorOrHtmlElements, delay);
  }
}
