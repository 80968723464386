/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export enum ColorSchemeName {
  light = 'ngx-pids-style-light',
  default = 'ngx-pids-style-default',
  dark = 'ngx-pids-style-dark'
}
