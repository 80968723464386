/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {scaleInOutAnimation} from '@pitagon/ngx-pids/animations';
import {ShareBottomSheetComponent} from '../../share-bottom-sheet/share-bottom-sheet.component';

@Component({
  selector: 'ngx-pids-widget-quick-value-center',
  templateUrl: './widget-quick-value-center.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [scaleInOutAnimation]
})
export class WidgetQuickValueCenterComponent implements OnInit {

  // @ts-ignore
  @Input() icon: string;
  // @ts-ignore
  @Input() value: string;
  // @ts-ignore
  @Input() label: string;
  // @ts-ignore
  @Input() change: number;
  // @ts-ignore
  @Input() helpText: string;
  // @ts-ignore
  @Input() iconClass: string;

  // @ts-ignore
  showButton: boolean;

  constructor(private _bottomSheet: MatBottomSheet) {
  }

  ngOnInit() {
  }

  openSheet() {
    this._bottomSheet.open(ShareBottomSheetComponent);
  }
}
