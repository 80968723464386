<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<div (click)="close()" [class.opaque]="invisibleBackdrop" [class.show]="opened" class="backdrop"></div>

<div [class.open]="opened" [class.position-left]="positionLeft" [class.position-right]="positionRight" class="sidebar">
  <ng-content></ng-content>
</div>
