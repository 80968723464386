/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, OnInit} from '@angular/core';
import {trackById} from '@pitagon/ngx-pids/utils';
import {DateTime} from 'luxon';
import {Notification} from '../interfaces/notification.interface';

@Component({
  selector: 'ngx-pids-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  notifications: Notification[] = [
    {
      id: '1',
      label: 'New Order Received',
      icon: 'mat:shopping_basket',
      colorClass: 'text-primary',
      datetime: DateTime.local().minus({hour: 1})
    },
    {
      id: '2',
      label: 'New customer has registered',
      icon: 'mat:account_circle',
      colorClass: 'text-orange',
      datetime: DateTime.local().minus({hour: 2})
    },
    {
      id: '3',
      label: 'Campaign statistics are available',
      icon: 'mat:insert_chart',
      colorClass: 'text-purple',
      datetime: DateTime.local().minus({hour: 5})
    },
    {
      id: '4',
      label: 'Project has been approved',
      icon: 'mat:check_circle',
      colorClass: 'text-green',
      datetime: DateTime.local().minus({hour: 9})
    },
    {
      id: '5',
      label: 'Client reports are available',
      icon: 'mat:description',
      colorClass: 'text-primary',
      datetime: DateTime.local().minus({hour: 30})
    },
    {
      id: '6',
      label: 'New review received',
      icon: 'mat:feedback',
      colorClass: 'text-orange',
      datetime: DateTime.local().minus({hour: 40}),
      read: true
    },
    {
      id: '7',
      label: '22 verified registrations',
      icon: 'mat:verified_user',
      colorClass: 'text-green',
      datetime: DateTime.local().minus({hour: 60})
    },
    {
      id: '8',
      label: 'New files available',
      icon: 'mat:file_copy',
      colorClass: 'text-amber',
      datetime: DateTime.local().minus({hour: 90})
    }
  ];

  trackById = trackById;

  constructor() {
  }

  ngOnInit() {
  }

}
