/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export * from './src/color-variables';
export * from './src/country-language.interface';
export * from './src/css-value.type';
export * from './src/deep-partial.type';
export * from './src/link.interface';
export * from './src/navigation-item.interface';
export * from './src/ngx-pids-route.interface';
export * from './src/table-column.interface';

export * from './src/auth/default-operation.type';
export * from './src/auth/default-resource.type';
