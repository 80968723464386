<div class="flex items-center d-flex justify-content-between" mat-dialog-title
     style="justify-content: space-between;">
  <h2 class="headline m-0" fxFlex="auto" translate>{{ defaults.title }}</h2>
  <button (click)="close('No answer')" class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p translate>{{ defaults.content }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="close('No')" mat-raised-button>{{ 'NO' | translate }}</button>
  <button (click)="close('Yes')" color="{{ defaults.colorBtn }}" mat-raised-button>{{ 'YES' | translate }}</button>
</mat-dialog-actions>
