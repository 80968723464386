/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export enum DefaultResourceType {
  USER = "USER",
  ORG = "ORG",
  ROLE = "ROLE",
  MENU = "MENU",
  ROLE_MENU = "ROLE_MENU",
  ROLE_PERMISSION = "ROLE_PERMISSION",
  COM_CAT = "COM_CAT",
  COM_CAT_TYPE = "COM_CAT_TYPE",
  SYSTEM_LANGUAGE = "SYSTEM_LANGUAGE",
}
