<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<div class="footer flex">
  <div class="flex-auto flex items-center container">
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      NgxPiDS © {{currentYear}} <a href="https://pitagon.io/" target="_blank">Pitagon</a>.
    </div>
  </div>
</div>
