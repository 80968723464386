<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<div class="ngx-pids-user-menu">
  <a (click)="close()"
     [routerLink]="['/apps/social']"
     class="ngx-pids-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="ngx-pids-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="ngx-pids-user-menu-item__label">Your Profile</div>
  </a>

  <a (click)="close()"
     [routerLink]="['/']"
     class="ngx-pids-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="ngx-pids-user-menu-item__icon icon-sm" svgIcon="mat:insights"></mat-icon>
    <div class="ngx-pids-user-menu-item__label">Analytics</div>
    <div class="ngx-pids-user-menu-item__badge">NEW</div>
  </a>

  <a (click)="close()"
     [routerLink]="['/apps/social/timeline']"
     class="ngx-pids-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="ngx-pids-user-menu-item__icon icon-sm" svgIcon="mat:settings"></mat-icon>
    <div class="ngx-pids-user-menu-item__label">Account Settings</div>
  </a>

  <div class="border-b border-divider mx-4"></div>

  <div class="ngx-pids-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="ngx-pids-user-menu-item__icon icon-sm" svgIcon="mat:switch_account"></mat-icon>
    <div class="ngx-pids-user-menu-item__label">Switch Account</div>
    <mat-icon class="ngx-pids-user-menu-item__icon icon-sm" svgIcon="mat:chevron_right"></mat-icon>
  </div>

  <a (click)="signOut();" class="ngx-pids-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="ngx-pids-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="ngx-pids-user-menu-item__label">{{'label.signOut'|translate}}</div>
  </a>
</div>
