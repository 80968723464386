/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PopoverComponent} from './popover.component';

@NgModule({
  declarations: [PopoverComponent],
  imports: [
    CommonModule,
    OverlayModule
  ]
})
export class PopoverModule {
}
