/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {AfterContentInit, Component, ElementRef, Input, NgZone, OnDestroy} from '@angular/core';
import SimpleBar from 'simplebar';

@Component({
  selector: 'ngx-pids-scrollbar',
  template: `
    <ng-content></ng-content>`,
  styleUrls: ['./scrollbar.component.scss'],
  host: {
    class: 'ngx-pids-scrollbar'
  },
})
export class ScrollbarComponent implements AfterContentInit, OnDestroy {

  // @ts-ignore
  @Input() options: Partial<any>;

  // @ts-ignore
  scrollbarRef: SimpleBar;

  constructor(private _element: ElementRef,
              private zone: NgZone) {
  }

  ngAfterContentInit() {
    this.zone.runOutsideAngular(() => {
      this.scrollbarRef = new SimpleBar(this._element.nativeElement, this.options);
    });
  }

  ngOnDestroy(): void {
    /**
     * Exists, but not typed in the type definition
     * https://github.com/Grsmto/simplebar/blob/master/packages/simplebar/src/simplebar.js#L903
     */
    if (this.scrollbarRef && (this.scrollbarRef as any).unMount) {
      (this.scrollbarRef as any).unMount();
    }
  }
}
