/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, OnInit} from '@angular/core';
import {NavigationItem} from '@pitagon/ngx-pids/interfaces';
import {NavigationService} from '@pitagon/ngx-pids/services';

@Component({
  selector: 'ngx-pids-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  items: NavigationItem[] = this.navigationService.items;

  constructor(private navigationService: NavigationService) {
  }

  ngOnInit() {
  }
}
