/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {WidgetAssistantComponent} from './widget-assistant.component';

@NgModule({
  declarations: [WidgetAssistantComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [WidgetAssistantComponent]
})
export class WidgetAssistantModule {
}
