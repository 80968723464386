/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CountryLanguage, CSSValue} from '@pitagon/ngx-pids/interfaces';
import {ColorSchemeName} from './colorSchemeName';
import {NgxPiDsConfigName} from './config-name.model';

export interface NgxPiDsConfig {
  id: NgxPiDsConfigName;
  name: string;
  imgSrc: string;
  direction: 'ltr' | 'rtl';
  style: {
    colorScheme: ColorSchemeName,
    colors: {
      primary: {
        default: string;
        contrast: string;
      }
    };
    borderRadius: CSSValue;
    button: {
      borderRadius: CSSValue;
    }
  };
  layout: 'vertical' | 'horizontal';
  boxed: boolean;
  sidenav: {
    title: string;
    imageUrl: string;
    showCollapsePin: boolean;
    user: {
      visible: boolean;
    };
    search: {
      visible: boolean;
    };
    state: 'expanded' | 'collapsed';
  };
  toolbar: {
    fixed: boolean;
    user: {
      visible: boolean;
    }
  };
  navbar: {
    position: 'below-toolbar' | 'in-toolbar';
  };
  footer: {
    visible: boolean;
    fixed: boolean;
  };
  i18n: {
    languages: CountryLanguage[];
    defaultLanguage: CountryLanguage;
  };
  security: {
    auth: {
      operations: string[];
      resources: string[];
    }
  };
  remoteApi: {};
  route: {};
}
