/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {colorVariables} from '@pitagon/ngx-pids/interfaces';
import {mergeDeep} from '@pitagon/ngx-pids/utils';
import {ColorSchemeName} from './colorSchemeName';
import {NgxPiDsConfigName} from './config-name.model';
import {NgxPiDsConfig} from './ngx-pids-config.interface';

const defaultConfig: NgxPiDsConfig = {
  id: NgxPiDsConfigName.pids,
  name: 'PiDS',
  style: {
    colorScheme: ColorSchemeName.default,
    colors: {
      primary: colorVariables.pids
    },
    borderRadius: {
      value: 1,
      unit: 'rem'
    },
    button: {
      // @ts-ignore
      borderRadius: undefined
    }
  },
  direction: 'ltr',
  imgSrc: 'assets/images/layouts/pids.png',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'Pitagon',
    imageUrl: 'assets/app-logo/logo.svg',
    showCollapsePin: true,
    user: {
      visible: true
    },
    search: {
      visible: true
    },
    state: 'expanded'
  },
  toolbar: {
    fixed: true,
    user: {
      visible: true
    }
  },
  navbar: {
    position: 'below-toolbar'
  },
  footer: {
    visible: true,
    fixed: true
  },
  i18n: {
    languages: undefined,
    defaultLanguage: undefined,
  },
  security: {
    auth: {
      operations: [],
      resources: [],
    }
  },
  remoteApi: undefined,
  route: undefined,
};

export const configs: NgxPiDsConfig[] = [
  defaultConfig,
  mergeDeep({...defaultConfig}, {
    id: NgxPiDsConfigName.pitagon,
    name: 'Pitagon',
    imgSrc: 'assets/images/layouts/pitagon.png',
    style: {
      colorScheme: ColorSchemeName.light,
      colors: {
        primary: colorVariables.pids
      },
      borderRadius: {
        value: 1,
        unit: 'rem'
      },
      button: {
        borderRadius: {
          value: 16,
          unit: 'px'
        }
      }
    },
    sidenav: {
      user: {
        visible: true
      },
      search: {
        visible: true
      }
    },
    toolbar: {
      user: {
        visible: true
      }
    },
    footer: {
      fixed: false
    }
  }),
  mergeDeep({...defaultConfig}, {
    id: NgxPiDsConfigName.pioffice,
    name: 'PiOffice',
    imgSrc: 'assets/images/layouts/pioffice.png',
    style: {
      borderRadius: {
        value: 0.5,
        unit: 'rem'
      },
      button: {
        borderRadius: {
          value: 9999,
          unit: 'px'
        }
      }
    },
    sidenav: {
      user: {
        visible: true
      },
      search: {
        visible: true
      }
    },
    toolbar: {
      user: {
        visible: false
      }
    },
    footer: {
      fixed: false
    }
  }),
  mergeDeep({...defaultConfig}, {
    id: NgxPiDsConfigName.pisafe,
    name: 'PiSafe',
    imgSrc: 'assets/images/layouts/pisafe.png',
    layout: 'vertical',
    boxed: true,
    sidenav: {
      user: {
        visible: false
      },
      search: {
        visible: false
      },
    },
    toolbar: {
      fixed: false
    },
    footer: {
      fixed: false
    },
  }),
  mergeDeep({...defaultConfig}, {
    id: NgxPiDsConfigName.piads,
    name: 'PiAds',
    imgSrc: 'assets/images/layouts/piads.png',
    sidenav: {
      user: {
        visible: false
      },
      search: {
        visible: false
      },
    },
    toolbar: {
      fixed: false
    },
    navbar: {
      position: 'in-toolbar'
    },
    footer: {
      fixed: false
    },
  }),
  mergeDeep({...defaultConfig}, {
    id: NgxPiDsConfigName.piweb,
    name: 'PiWeb',
    imgSrc: 'assets/images/layouts/piweb.png',
    sidenav: {
      state: 'collapsed'
    },
  }),
  mergeDeep({...defaultConfig}, {
    id: NgxPiDsConfigName.petapp,
    name: 'PetApp',
    imgSrc: 'assets/images/layouts/petapp.png',
    layout: 'vertical',
    boxed: true,
    sidenav: {
      user: {
        visible: false
      },
      search: {
        visible: false
      },
    },
    toolbar: {
      fixed: false
    },
    navbar: {
      position: 'in-toolbar'
    },
    footer: {
      fixed: false
    }
  })
];
