<!--
  ~ Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
  ~
  ~ Unauthorized copying or redistribution of this file in source and binary forms via any medium
  ~ is strictly prohibited.
  -->

<button #originRef (click)="showPopover()" [class.bg-hover]="dropdownOpen" class="button" mat-icon-button type="button">
  <mat-icon color="primary" svgIcon="mat:notifications_active"></mat-icon>
</button>
