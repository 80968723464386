/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {I18nSwitcherComponent} from './i18n-switcher.component';

@NgModule({
  declarations: [
    I18nSwitcherComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule
  ],
  exports: [I18nSwitcherComponent]
})
export class I18nSwitcherModule {
}
