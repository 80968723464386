/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CSSValue} from '@pitagon/ngx-pids/interfaces';

export const defaultRoundedButtonBorderRadius: CSSValue = {
  value: 9999,
  unit: 'px'
};
