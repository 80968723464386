/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "@pitagon/ngx-pids/security";
import {PopoverRef} from '../popover/popover-ref';

@Component({
  selector: 'ngx-pids-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(private readonly popoverRef: PopoverRef,
              private readonly authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  signOut(): void {
    this.authenticationService.logOut();
  }
}
