/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StripHtmlPipe} from './strip-html.pipe';

@NgModule({
  declarations: [StripHtmlPipe],
  imports: [
    CommonModule
  ],
  exports: [StripHtmlPipe]
})
export class StripHtmlModule {
}
