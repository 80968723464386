import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogConfirm} from "./interfaces/DialogConfirm";

@Component({
  selector: 'pids-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {
  icClose = 'icClose';

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: DialogConfirm,
    private dialogRef: MatDialogRef<DialogConfirmComponent>) {
  }

  close(answer: string) {
    this.dialogRef.close(answer);
  }

  ngOnInit(): void {
  }

}
