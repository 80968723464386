/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {MegaMenuComponent, PopoverService} from '@pitagon/ngx-pids/components';
import {ConfigService} from '@pitagon/ngx-pids/config';
import {NavigationItem} from '@pitagon/ngx-pids/interfaces';
import {LayoutService, NavigationService} from '@pitagon/ngx-pids/services';
import {GeneralComponent} from "@pitagon/ngx-pids/utils";
import {Observable, of} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'ngx-pids-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends GeneralComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @Input()
  mobileQuery: boolean;

  // @ts-ignore
  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems: NavigationItem[] = this.navigationService.items;

  // @ts-ignore
  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  // @ts-ignore
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  // @ts-ignore
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  // @ts-ignore
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  // @ts-ignore
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));
  megaMenuOpen$: Observable<boolean> = of(false);

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService) {
    super();
  }

  ngOnInit() {
  }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }
}
