/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SidebarModule} from '@pitagon/ngx-pids/components';
import {LayoutModule, QuickpanelModule, SidenavModule, ToolbarModule} from '@pitagon/ngx-pids/layout';
import {AppLayoutComponent} from './app-layout.component';
import {FooterModule} from './footer/footer.module';

@NgModule({
  declarations: [AppLayoutComponent],
  imports: [
    CommonModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    FooterModule,
    // ConfigPanelModule,
    SidebarModule,
    QuickpanelModule
  ]
})
export class AppLayoutModule {
}
