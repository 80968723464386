/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {CountryLanguage} from "@pitagon/ngx-pids/interfaces";

export const DEFAULT_COUNTRY_LANGUAGE_DATASOURCE: CountryLanguage[] = [
  {
    countryCode: "US",
    languageCode: "en",
    languageEnglishName: "English (US)",
    languageNativeName: "English",
    flag: "united-states",
  },
  {
    countryCode: "VN",
    languageCode: "vi",
    languageEnglishName: "Vietnamese",
    languageNativeName: "Tiếng Việt",
    flag: "vietnam",
  },
];
