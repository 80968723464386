/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {Injectable} from "@angular/core";
import {ConfigService} from "@pitagon/ngx-pids/config";
import {CountryLanguage} from "@pitagon/ngx-pids/interfaces";

@Injectable({
  providedIn: 'root'
})
export class CountryLanguageService {

  private _currentCountryLanguage: CountryLanguage;

  constructor(private configService: ConfigService) {
  }

  getByCountryCode(countryCode: string): CountryLanguage {
    return this.configService.currentConfig?.i18n?.languages?.find(item => item.countryCode === countryCode);
  }

  getByLanguageCode(languageCode: string): CountryLanguage {
    return this.configService.currentConfig?.i18n?.languages?.find(item => item.languageCode === languageCode);
  }

  get currentCountryLanguage(): CountryLanguage {
    return this._currentCountryLanguage;
  }

  set currentCountryLanguage(value: CountryLanguage) {
    this._currentCountryLanguage = value;
  }

}
